/* You can add global styles to this file, and also import other style files */
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

.form-field-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
}

.form-field-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.form-field-invalid-feedback-lg {
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
}

.form-field-is-valid .form-control {
  border-color: #28a745;
}

.form-field-is-valid input.form-control:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.form-field-is-valid .form-control {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-field-is-invalid input.form-control {
  border-color: #dc3545;
}

.form-field-is-invalid input.form-control:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-field-is-invalid .form-control {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.grid > .row {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}

.form-grid .row {
  padding-bottom: 0.25em;
  padding-top: 0.25em;
}

.grid > *:hover {
  background-color: #b8daff !important;
}

.grid-striped > *:nth-of-type(even),
.form-grid-striped .row:nth-of-type(even),
.form-striped .form-field:nth-of-type(even) form-field-wrapper .form-group.row {
  background-color: #f8f9fa;
}

.striped-radio:nth-of-type(odd) {
  background-color: #EDEFF1;
}

.grid > .row:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.bs-timepicker-field {
  background-image: none !important;
  padding: 0.375rem 0.55rem !important;
}

.multiline {
  white-space: pre-line;
}

.whitespace-prefix:before {
  content: "\00a0 ";
}

.strong {
  font-weight: bold;
}

.container--available-viewport-height {
  height: calc(100vh - 245px);
}

.container--available-viewport-min-height {
  min-height: calc(100vh - 245px);
}

.h-95 {
  height: 95% !important
}

.scroll-to-bottom {
  display: flex;
  flex-direction: column-reverse;
}

.in-front-of-everything {
  z-index: 1100;
}

.generator-title {
  color: #006ea0;
}

.generator-badge {
  font-size: 100%;
  vertical-align: center;
  height: fit-content;
}

a.landing-page-link {
  color: white;
  text-decoration: underline;
}

.landing-page-text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.landing-page-input:focus {
  border-color: rgba(96, 172, 184, 1) !important;
  box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.1);
}


.data-form-landing-page-background {
  background: url('assets/landing-page/data-form-background.jpg');
  background-size:100% 100%;
  background-position:51% 8%;
  background-attachment:scroll;
  background-repeat:no-repeat;
  min-height: 100vh;
}

.home-holter-landing-page-background {
  background: url('assets/landing-page/home-holter-background.jpg');
  background-size:100% 100%;
  background-position:51% 8%;
  background-attachment:scroll;
  background-repeat:no-repeat;
  min-height: 100vh;
}

